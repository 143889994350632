<template>
    <v-card iv v-if="'PAR' == partner_type" class="mb-5">
        <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{$t("order_for")}}</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-text>

            <v-autocomplete :items="order_for_choices" :label="$t('order_for')"
                :value="order_for_number" @change="setParForCustomer"
                :hint="$t('order_for_hint')"
                persistent-hint 
                :placeholder="$t('select_customer')">
            </v-autocomplete>
        </v-card-text>

    </v-card>
</template>

<script>
import client from "@/plugins/axios.js"
export default {
    name: "OrderForSelection",
    data: function () {
        return {
            loading: false
        }
    },
    computed: {
        customer_number: function () {
            return this.$store.state.session.number;
        },
        partner_type: function () {
            return this.$store.state.session.partnerType;
        },
        order_for_number: function () {
            return this.$store.state.gfoWebcomponent.order_for.order_for_number
        },
        order_for_choices: function () {
            return this.$store.state.gfoWebcomponent.order_for_choices
        }
    },
    methods: {
        setParForCustomer: function (val) {
            let selected_customer = this.order_for_choices.filter(function (item) {
                return item.value == val
            })[0]
            if (selected_customer){
                let order_for = {
                    order_by_number: this.customer_number,
                    order_for_number: val,
                    order_for_name: selected_customer.text,
                    order_for_has_active_products: 0 < selected_customer.number_active_products
                }
                this.$store.commit('gfoWebcomponent/order_for', order_for)
            }
        },
        loadOrderForChoices: function () {
            let customer_number = this.customer_number
            let that = this
            let choices = [
                {
                    value: "",
                    text: "bitte wählen", 
                    number_active_products: 0
                },
                {
                    value: "0",
                    text: "für neuen Kunden",
                    number_active_products: 0, // keine aktiven Produkte
                },
                {
                    value: customer_number.toString(),
                    text: `mich selbst (KdNr ${customer_number})`,
                    number_active_products: 1 // Ich gehe davon aus dass ein PAR aktive Produkte hat
                }
            ]
            this.loading = true
            client.get("/customers", { disableDefaultErrorHandler: false })
                .then(response => {
                    response.data.forEach(function (row) {
                        if (row.number != customer_number) {
                            choices.push({
                                value: row.number.toString(),
                                text: row.name, 
                                number_active_products: row.number_active_products
                            })
                        }
                    })
                })
                .finally(function () {
                    that.$store.commit("gfoWebcomponent/order_for_choices", choices)
                    that.loading = false
                });
        },
    },
    mounted: function () {
        if ("PAR" == this.partner_type && 0 == this.order_for_choices.length) {
            this.loadOrderForChoices()
        }
    }
}
</script>


<i18n>
{
  "en": {
   "select_customer": "Select customer",
   "order_for":"Order for",
   "order_for_hint":"As partner you can select who to order for"
  },
  "de": {
   "select_customer": "Kunde wählen",
   "order_for":"Bestellung für",
   "order_for_hint":"Als Partner können Sie wählen für wen Sie bestellen"
  }
}
</i18n>