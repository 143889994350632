<template>
  <div>

    <OrderForSelection v-if="'PAR' == partner_type"></OrderForSelection>

    <div style="position:relative;">
      <v-overlay v-if="show_overlay"
        style="margin:-15px;"
        absolute></v-overlay>
        <v-card v-if="0 < getNetzeOfType(netz_type).length" 
          class="mb-3"
          >
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ netz_type_name }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3"
                v-for="netz in getNetzeOfType(netz_type)" :key="netz.id">
                <v-btn block light
                  :to="{ name: 'gfo-offer-checkout', params: { netz_slug: netz.slug } }">
                  {{ netz.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="!loading && 0 == getNetzeOfType(netz_type).length" class="mb-3"
          >
          <v-card-text>Keine Angebote dieses Typs gefunden</v-card-text>
        </v-card>
    </div>
  </div>
</template>

<script>
import OrderForSelection from "@/components/orders/gfoOrder/OrderForSelection.vue"
export default {
  name: "ResellerGfoNetzOverview",
  components: { OrderForSelection },
  props: {
    netz_type: {
      type: [String],
      required: true,
      default: null,
    },
  },
  data: function () {
    return {
      netze: [],
      loading: false,
      netz_type_names: {
        "phone": "Telefonie",
        "mobile": "Mobile",
        "tv": "TV",
        "hosting": "Hosting",
        "domain": "Domains",
        "gen": "Andere",
      }
    }
  },
  computed: {
    netz_type_name: function(){
      if (typeof(this.netz_type_names[this.netz_type]) !== "undefined"){
        return this.netz_type_names[this.netz_type];
      } else {
        return this.netz_type;
      }
    },
    show_overlay: function(){
      return this.loading || ('PAR' == this.partner_type && '' == this.order_for_number)
    },
    partner_type: function () {
      return this.$store.state.session.partnerType;
    },
    order_for_number: function(){
      return this.$store.state.gfoWebcomponent.order_for.order_for_number
    },
    api_token: function () {
      if ('END' == this.partner_type || 
        ('PAR' == this.partner_type && '0' != this.order_for_number)
      ) {
        // Endkunde oder Partner der für Endkunde bestellt
        return this.$config.gfoApiTokenEnd;
      } else if ('WHO' == this.partner_type) {
        return this.$config.gfoApiTokenWho;
      } else {
        return this.$config.gfoApiTokenPar;
      }
    },
    api_url: function () {
      return this.$config.gfoApiUrl;
    },
    netze_filtered: function () {
      return this.netze.filter(function (item) {
        if (-1 < ['gf', 'dsl'].indexOf(item.anschluss)) {
          return false;
        } else {
          return true;
        }
      });
    },

  },
  methods: {
    load: async function () {
      this.loading = true;
      try {
        let resp = await this.callApi('GET', '/netze');
        if (resp.hasOwnProperty('data')) {
          this.netze = resp.data;
        } else {
          this.error = 'Problem loading GFO netze';
        }
      } catch (e) {
        this.error = 'Problem loading GFO netze';
      }
      this.loading = false;
    },
    callApi: async function (method, path, data) {
      let api_token = this.api_token;
      let api_url = this.api_url;
      return new Promise(function (resolve, reject) {
        var http = new XMLHttpRequest();
        var url = api_url + path
        http.open(method.toUpperCase(), url, true);
        http.setRequestHeader('Accept', 'application/json');
        http.setRequestHeader('X-API-TOKEN', api_token);
        if (null == data) {
          http.send(null);
        } else {
          http.send(JSON.stringify(data));
        }
        http.onreadystatechange = function () {
          if (4 == http.readyState) {
            if (200 <= http.status && 400 > http.status) {
              var obj = http.responseText;
              try {
                obj = JSON.parse(http.responseText);
              }
              catch (err) {
                console.log('JSON parse failed ' + path);
              }
              resolve({
                data: obj,
                xhr: http
              })
            } else {
              var obj = http.responseText;
              try {
                obj = JSON.parse(http.responseText);
              }
              catch (err) {
                console.log('JSON parse failed');
              }
              reject({
                data: obj,
                xhr: http
              });
            }
          }
        }
      });
    },
    getNetzeOfType: function (type) {
      return this.netze_filtered.filter(function (item) {
        return item.type == type;
      });
    }
  },
  watch: {
    order_for_number: function(){
      this.load()
    }
  },
  created: async function () {
    await this.load();
  }
};
</script>

<i18n>
{
  "en": {
   "gfo_netz_overview": "netze"
  },
  "de": {
   "gfo_netz_overview": "Angebote"
  }
}
</i18n>

